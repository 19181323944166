<template>
  <div class="mt-15 mb-5">
    <v-layout justify-end>
      <!-- <v-btn @click="ir()" color="#466BE3" text rounded class="mb-5">
        <strong> Abrir en una pestaña nueva </strong>
      </v-btn> -->
    </v-layout>

    <iframe
      :width="widthI"
      :height="height"
      :src="url"
      frameborder="0"
      style="border: 0"
      allowfullscreen
    ></iframe>
  </div>
</template>
<script>
export default {
  data() {
    return {
      url: "",
      widthI: null,
      height: null,
    };
  },
  methods: {
  },
  mounted() {
    if (screen.width > 2000) {
      this.widthI = screen.width / 1.8;
      this.height = screen.height / 1.3;
    } else if (screen.width > 1500 && screen.width <= 2000) {
      this.widthI = screen.width / 1.4;
      this.height = screen.height / 1.5;
    } else if (screen.width > 900 && screen.width <= 1500) {
      this.widthI = screen.width / 1.1;
      this.height = screen.height / 1.2;
    } else {
      this.widthI = screen.width;
      this.height = screen.height;
    }

    this.url = localStorage.getItem("URL");
    console.log(this.url)
  },
};
</script>
